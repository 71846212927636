
import { Options, Vue } from "vue-class-component";
import BackToInvoicesButton from "@/components/tuition-fees/BackToInvoicesButton.vue";
import MirroringUserBanner from "@/components/tuition-fees/MirroringUserBanner.vue";
import { SessionStorageKeys } from "@/constants/constants";
import UnauthorizedHelper from "@/helpers/tuitionfees/unauthorized-helper";
import {
  RegisterCardResponse,
  NetsTerminalResponse,
  RegisterCardRequest,
  TfPerson,
} from "@/interfaces/api";
import SessionStorageService from "@/services/sessionstorage-service";
import PaymentService from "@/services/tuition-fees/payment-service";

@Options({
  components: {
    BackToInvoicesButton,
    MirroringUserBanner,
  },
  data: () => ({
    /**
     * The current user object
     */
    currentUser: {},
    /**
     * The personal id of the user being mirrored by admin
     */
    personalId: "",
    /**
     * The name of the user being mirrored by admin
     */
    personName: "",
    /**
     * The lucat id of the user being mirrored by admin
     */
    lucatId: "",
    /**
     * A boolean to indicate if tfp-admin is mirroring user or not
     */ isMirroringUser: false,
  }),
  computed: {
    /**
     * The current user
     */
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    /**
     * Gets the personal id, lucat id and name of the person being mirrored
     */
    getUserId(): void {
      const mirroredUser: TfPerson | undefined = SessionStorageService.getItem(
        SessionStorageKeys.MirroredUser
      );

      if (mirroredUser) {
        this.personName = mirroredUser.displayName;
        this.personalId = mirroredUser.personalId;
        this.lucatId = mirroredUser.luEduPersonPrimaryId;
      }
    },
    /**
     * Makes a register payment call to backend and redirects to payment page via nets API
     */
    performPayment(): void {
      this.$store.commit("showSpinner", true);

      const invoiceNo = this.$route.params.invoiceNo;
      const customerEmail = this.currentUser.email;

      const registerCardRequest: RegisterCardRequest = {
        invoiceNo: invoiceNo,
        customerEmail: customerEmail,
      };

      PaymentService.registerPayment(registerCardRequest)
        .then((registerPaymentResponse: RegisterCardResponse) => {
          if (registerPaymentResponse.resourceUrl) {
            this.netsUrl = registerPaymentResponse.resourceUrl;
            try {
              this.redirectToNetsPaymentTerminal(
                registerPaymentResponse.resourceUrl
              );
            } catch (error) {
              this.saveTransactionResultAndRouteToResultPage(error);
            }
          } else {
            this.saveTransactionResultAndRouteToResultPage(
              registerPaymentResponse.error
            );
          }
        })
        .catch((e: Error) => {
          console.error(e);
        });
    },
    /**
     * Submits the form in order to redirect to the nets payment terminal
     */
    redirectToNetsPaymentTerminal(resourceUrl: string): void {
      try {
        const netsForm = this.$refs.netsForm;
        netsForm.action = resourceUrl;
        netsForm.submit();
      } catch (e) {
        this.saveTransactionResultAndRouteToResultPage(e);
      }
    },
    /**
     * Gets the nets response from the route query
     * and calls the backend to process the payment
     */
    getNetsResponse(): void {
      const invoiceNo = this.$route.params.invoiceNo;
      const transactionId = this.$route.query.transactionId;
      const responseCode = this.$route.query.responseCode;

      if (transactionId) {
        this.$store.commit("showSpinner", true);

        const terminalRespone: NetsTerminalResponse = {
          transactionId: transactionId,
          responseCode: responseCode,
          invoiceNumber: invoiceNo,
        };

        PaymentService.handleNetsTerminalResponse(terminalRespone)
          .then((result: string) => {
            this.saveTransactionResultAndRouteToResultPage(result);
          })
          .catch((e: Error) => {
            this.saveTransactionResultAndRouteToResultPage(e);
          });
      }
    },
    /**
     * Saves the transaction result in session storage and routes to the payment response page
     */
    saveTransactionResultAndRouteToResultPage(operationResult: string): void {
      this.getUserId();

      SessionStorageService.setItem(
        SessionStorageKeys.PaymentResponse,
        operationResult
      );

      this.$router.push({
        name: "PaymentResponse",
      });
    },

    getCurrentEntitlements() {
      this.currentUser = this.$store.getters.getCurrentUser;
    },
    /**
     * If not logged in or not a student, redirect to unauthorized page
     * Otherwise, check if admin is mirroring a user, if so show banner
     * if not redirect to unauthorized page
     */
    checkIfUserIsStudentOrAdminMirroringUser(): void {
      this.isMirroringUser =
        UnauthorizedHelper.checkIfUserIsStudentOrAdminMirroringUser(
          this.currentUser
        );
    },
    /**
     * Scrolls to the top of the page to solve a problem where the page is scrolled down
     * on load from when the user is positioned low on tuition fees page
     */
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getUserId();
    this.getNetsResponse();
    this.scrollToTop();
    this.getCurrentEntitlements();
    this.checkIfUserIsStudentOrAdminMirroringUser();
  },
})
export default class CardPayment extends Vue {}
